import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
import { EcaSnackbarAlertComponent, EcaSnackbarAlertConfig, AppContextService, EcaGenericMessageDialogConfig, EcaGenericMessageDialogComponent } from '@drc-eca/eca-components-lib';
import { UserSecurityService } from '../../user/services/user-security.service';
import { PortalError } from '../../shared/portal-error.model';
import { UserManagementService } from '../../user/services/user-management.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  temporaryToken: string;
  userId: string;
  isLoading = new BehaviorSubject(false);
  isLoading$ = this.isLoading.asObservable();
  productCode: string;
  userEmail: string;
  requestedNewLink = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userSecurityService: UserSecurityService,
    private userManagementService: UserManagementService,
    private appContextService: AppContextService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.appContextService.setSelectedProductCode('');

    this.activatedRoute.queryParams.subscribe(params => {
      this.temporaryToken = params['token'].replace(/(\r\n|\n|\r)/gm, '');
      this.userId = (jwtDecode(this.temporaryToken) as any).sub;
      this.productCode = params['productCode'];
      this.userEmail = (jwtDecode(this.temporaryToken) as any).username;
      if (this.productCode) {
        this.appContextService.setSelectedProductCode(this.productCode);
      }
    });
  }

  openGoodSnackBar(inMessage: string) {
    this.snackBar.openFromComponent(EcaSnackbarAlertComponent, {
      duration: 2000,
      panelClass: 'mat-snack-positive',
      verticalPosition: 'top',
      data: {
        icon: 'check_circle',
        message: inMessage
      }
    });
  }

  openBadSnackBar(err: string) {
    const config = new EcaSnackbarAlertConfig(err, 'negative');

    this.snackBar.openFromComponent(EcaSnackbarAlertComponent, config);
  }

  openDialog(err: PortalError) {
    const data: EcaGenericMessageDialogConfig = {
      message: err.friendlyErrorMessage,
      title: 'Expired Password Reset Link',
      icon: 'warning-triangle',
      buttons: {
        negative: {
          label: 'Cancel'
        },
        positive: {
          label: 'Send a New Link'
        }
      }
    };

    const dialog = this.dialog.open(EcaGenericMessageDialogComponent, {
      width: '600px',
      panelClass: 'activate-account-dialog',
      data: data
    })
      dialog.beforeClosed()
      .pipe(take(1))
      .subscribe((sendLink) => {
        if (sendLink) {
          this.resendEmail();
        }
      });
  }

  resendEmail() {
    this.loading(true);
    this.userManagementService.forgotPassword(this.userEmail, this.productCode)
      .subscribe(() => {
          this.loading(false);
          this.requestedNewLink = true;
        },
        (err) => {
          this.loading(false);
          this.openBadSnackBar('There was a problem sending the email.');
        }
      );
  }

  updatePassword(newPassword) {
    const pswdObject = {
      password: newPassword,
      token: this.temporaryToken
    };
    this.userSecurityService.updateWithNewPassword(this.userId, pswdObject).subscribe(
      (user) => {
        this.appContextService.setToken(user.token);
        this.userSecurityService.getUserForToken(user.token).subscribe(() => {
          this.appContextService.setUserId(user.uid);
          //get the user details for user menu
          this.openGoodSnackBar('Your password has been successfully changed.');

          this.router.navigate(['/products']);
        });
      },
      (err: PortalError) => {
        this.loading(false);
        if (err.status === 403) {
          this.openDialog(err);
        } else {
          const friendlyMessage = err.friendlyErrorMessage + ' Please request a new password reset token.';
          this.openBadSnackBar(friendlyMessage);
        }
      }
    );
  }

  loading(event) {
    this.isLoading.next(event);
  }

}
