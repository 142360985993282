<app-new-password formTitle="Reset Password" [userId]="userId" [token]="temporaryToken" *ngIf="!requestedNewLink else showRequestedNewLink"
  formSubtitle="You have requested a new password or your password has expired. Please choose a new password. You may NOT use any of your last 5 passwords."
  saveButtonText="Update Password" (isLoading)="loading($event)" (formSubmit)="updatePassword($event)">
  <div class="ng-content-after">
    <br>
    <a routerLink="/forgot-password">Request new password reset token </a>
  </div>
</app-new-password>
<ng-template #showRequestedNewLink>
  <eca-pillar-box>
    <eca-form-column>
      <h1 class="eca-skip-link-focus-target" tabindex="-1">New Password Reset Request Submitted</h1>
      <p>
        Thank you for submitting your password reset request.
        Please check your email for the next steps to change your password.
      </p>
    </eca-form-column>
  </eca-pillar-box>
</ng-template>

<div class="spinner-overlay" fxLayout fxLayoutAlign="center center" *ngIf="isLoading$ | async">
  <mat-spinner diameter="48" color="primary"></mat-spinner>
</div>
