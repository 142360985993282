import { OnInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ApiGatewayService, AppContextService, WindowService } from '@drc-eca/eca-components-lib';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-short-token-redirect',
  templateUrl: './short-token-redirect.component.html',
  styleUrls: ['./short-token-redirect.component.scss']
})
export class ShortTokenRedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiGatewayService: ApiGatewayService,
    private appContextService: AppContextService,
    private windowService: WindowService,
    public dialog: MatDialog
  ) {}

  getRoute(url: string) {
    const route = url.split('eca-portal-v2-ui/')[1];
    const n = route.lastIndexOf('/');
    return route.slice(0, n) + route.slice(n).replace('/', '');
  }

  async ngOnInit() {
    this.appContextService.setSelectedProductCode('');
    // The email will be similar to http://cdn-app-dev.drcedirect.com/all/eca-portal-v2-ui/user-email/{ShortUrlCode}?productCode={PRODUCTCODE}
    // The Portal will reroute to   http://cdn-app-dev.drcedirect.com/all/eca-portal-v2-ui/#/user-email/{ShortUrlCode}?productCode={PRODUCTCODE}
    // Before this Component is executed so we can't use native URL
    // Might not include productCode=PRODUCTCODE
    const productCode = this.windowService.location.href.split('productCode=')[1]?.split('&')[0];
    if (productCode) {
      this.appContextService.setSelectedProductCode(productCode);
    }

    const { shortToken } = await this.route.params.pipe(take(1)).toPromise();
    const apiConfig = this.apiGatewayService.makeApiConfigString();
    const urlBase = `eca-security-service/${apiConfig}/v0/email/`;

    try {
      const response: any = await this.apiGatewayService.get(`${urlBase}shortUrl/${shortToken}`).toPromise();
      // This section is mostly redundant
      if (response.redirectUrl.includes('productCode')) {
        // The response is a properly structured URL so we can use native URL
        // http://cdn-app-dev.drcedirect.com/all/eca-portal-v2-ui/reset-password/?token={JWT}&productCode={PRODUCTCODE}
        const responseUrl = new URL(response.redirectUrl);
        const productCode = responseUrl.searchParams.get('productCode') as string;
        this.appContextService.setSelectedProductCode(productCode);
      }
      // Check url freshness for password resets
      if (response.redirectUrl.includes('reset-password')) {
        const freshnessResponse: any = await this.apiGatewayService.get(`${urlBase}checkShortUrlFresh/${shortToken}`).toPromise();
        if (freshnessResponse.shortUrlStatus !== 'fresh') {
          const urlBadText = 'This link has expired. You will not be able to update your password with this link. Please check your email inbox for a more recent reset-password link, or try clicking the reset password link again.';
          throw new HttpErrorResponse({status: 410, statusText: urlBadText});
        }
      }
      const targetRoute = this.getRoute(response.redirectUrl);

      await this.router.navigateByUrl(targetRoute);
    } catch (e) {
      const expiredText = 'This link has expired. Please click the reset password link again.';
      const errorPageText = e.status === 404 ? expiredText : e.statusText;
      history.pushState({}, '', location.origin + location.pathname + '#/forgot-password');
      await this.router.navigate([`/error/${e.status}`], { queryParams: { desc: errorPageText } });
    }
  }
}
